import React from 'react'
import { Platform, GestureResponderEvent } from 'react-native'

import { useLinkClickHandler, type NavigateOptions } from '~/navigation'
import {
  TextOrButton,
  type TextOrButtonProps,
  type TextOrButtonRef,
} from './TextOrButton'

export type RoutedLinkProps = TextOrButtonProps & NavigateOptions
export type RoutedLinkRef = TextOrButtonRef

/**
 * Render a link that will route to an internal app page. On web, this renders a
 * `Text` component which decomposes into an `<a>` tag. On native, this renders
 * a React Native `Pressable`.
 */
export const RoutedLink = React.forwardRef<RoutedLinkRef, RoutedLinkProps>(
  (
    {
      testID = 'RoutedLink',
      href,
      disabled,
      onPress: onPressProp,
      replace = false,
      state,
      relative,
      ...props
    },
    ref,
  ) => {
    const internalOnPress = useLinkClickHandler(href, {
      replace,
      state,
      relative,
    })

    const onPress = (e: GestureResponderEvent) => {
      onPressProp && onPressProp(e)
      if (!e.defaultPrevented) {
        internalOnPress(e as any)
      }
    }

    return (
      <TextOrButton
        testID={testID}
        disabled={disabled}
        // Only add the href/to prop if the button is not disabled.
        // `href={undefined}` won't work because any `href` or `to` prop changes
        // the cursor to "pointer" which we don't want when disabled.
        {...(!disabled
          ? Platform.select({
              web: { href, onPress },
              native: { onPress },
            })
          : {})}
        {...props}
        ref={ref}
      />
    )
  },
)
