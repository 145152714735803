import { useEnterExit } from '@thesoulfresh/react-tools'
import React from 'react'
import { ViewStyle } from 'react-native'
import { useIsFirstRender } from '~/hooks'
import { getEasing, getSpeed, useTheme } from '~/theme'
import { mergeRefs, removeNulls } from '~/utils'
import { AnimatedBox } from '../../core'
import type { StyleTransitionProps } from './types'
import { isTransformProperty } from './utils'

export const AnimatedStyleTransition = React.forwardRef(
  (
    {
      state: stateProp = true,
      animateEntrance = false,
      persistent = true,
      transitions = [],
      style,
      debug,
      children,
      ...rest
    }: StyleTransitionProps,
    ref,
  ) => {
    const theme = useTheme()
    const isFirstRender = useIsFirstRender()

    const [normalizedTransitions, longestTransition] = React.useMemo(() => {
      let longestTransition: number
      let longestTransitionIndex: number

      const _normalizedTransitions = transitions
        .map((curr, index) => {
          if (curr.property) {
            const isTransform = isTransformProperty(curr.property)
            const cssProperty = isTransform ? 'transform' : curr.property

            const easing = getEasing(theme, curr.easing) || 'linear'
            const duration = getSpeed(theme, curr.duration ?? 'm')
            const delay = getSpeed(theme, curr.delay)

            const transitionLength = (duration || 0) + (delay || 0)
            if (!longestTransition) {
              longestTransition = transitionLength
              longestTransitionIndex = 0
            } else {
              if (longestTransition < transitionLength) {
                longestTransition = transitionLength
                longestTransitionIndex = index
              }
            }

            return {
              ...curr,
              isTransform,
              cssProperty,
              easing,
              duration: duration != null ? `${duration}ms` : undefined,
              delay: delay != null ? `${delay}ms` : undefined,
            }
          } else {
            return undefined
          }
        })
        .filter(removeNulls)

      return [
        _normalizedTransitions,
        _normalizedTransitions[longestTransitionIndex],
      ]
    }, [transitions, theme])

    const { ref: animRef, state: entranceState } = useEnterExit(
      stateProp,
      longestTransition?.cssProperty,
      // Give the DOM a little time to settle before we start the animation.
      // This seems to fix an issue where in some cases animations advance
      // directly to the next state without animating. I think it has something
      // to do with React trying to batch updates to the DOM.
      { enterDelay: animateEntrance ? 60 : undefined },
    )

    let progress = entranceState === 'entered' ? 1 : 0
    if (!animateEntrance && isFirstRender && entranceState !== 'exited') {
      progress = 1 - progress
    }

    const previousState = React.useRef(
      animateEntrance
        ? entranceState === 'exited' || entranceState === 'entering'
          ? 'exited'
          : 'entered'
        : // If we are not animating the initial entrance, then default to the
          // state that we will enter on.
          entranceState === 'exited' || entranceState === 'entering'
          ? 'entered'
          : 'exited',
    )

    if (debug) {
      // prettier-ignore
      console.log(
        `[StyleTransition] RENDER -> "${progress}"`,
        '\n  state:', stateProp,
        '\n  animateEntrance:', animateEntrance,
        '\n  entranceState:', entranceState,
        '\n  previousState:', previousState.current,
      )
    }

    React.useEffect(
      () => {
        if (
          entranceState !== previousState.current &&
          (entranceState === 'exited' || entranceState === 'entered')
        ) {
          normalizedTransitions.forEach(({ onComplete }) => {
            onComplete && onComplete(progress, true)
          })
          previousState.current = entranceState
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [normalizedTransitions, entranceState],
    )

    if (entranceState === 'exited' && !persistent) {
      return null
    }

    type TransformType = ViewStyle['transform']

    // TODO Allow tranforms of different durations:
    // CSS doesn't provide a mechanism for setting different transitions for
    // each transform so you can't do a scale for 500ms with a translate of
    // 800ms. Instead you need to create multiple elements that each get
    // individual translations. We'd need to automate that here if we want to
    // match the functionality from the native side which does allow separate
    // transition durations per translation definition.
    const a = normalizedTransitions.reduce(
      (acc, curr) => {
        // Collect the transitions into an array now, concatenate them later
        if (!acc.transition) acc.transition = []
        // Remove duplicate transition definitions
        else {
          acc.transition = acc.transition.filter(
            (t) => !t.startsWith(curr.cssProperty + ' '),
          )
        }

        acc.transition.push(
          [curr.cssProperty, curr.duration, curr.easing, curr.delay]
            .filter((t) => !!t)
            .join(' '),
        )

        const value =
          typeof curr.value === 'function'
            ? curr.value(progress) || 0
            : progress

        if (debug) {
          // prettier-ignore
          console.log(
          '[StyleTransition] transition to', value,
          '\n  config', JSON.stringify(curr, null, 2),
        )
        }

        type NonStringTransform = Exclude<TransformType, string>
        function isNonStringTransform(
          transform: TransformType,
        ): transform is NonStringTransform {
          return typeof transform !== 'string'
        }

        if (curr.isTransform) {
          if (!acc.transform) acc.transform = []
          if (isNonStringTransform(acc.transform)) {
            acc.transform.push({
              [curr.property]: value,
            } as any)
          } else {
            // TODO What to do here?
            console.error(
              'Unable to append transition to an existing string transform',
            )
          }
        } else {
          acc[curr.cssProperty] = value
        }

        if (debug) {
          console.log(
            '[StyleTransition] animStyle',
            JSON.stringify(acc, null, 2),
          )
        }

        return acc
      },
      {} as { transition: string[]; transform: TransformType },
    )

    const animStyle = {
      ...a,
      transition: a.transition?.join(', '),
    }

    return (
      <AnimatedBox
        testID="StyleTransition"
        style={[style, animStyle]}
        ref={mergeRefs([ref, animRef])}
        children={children}
        {...rest}
      />
    )
  },
)

/**
 * An implementation of StyleTransition that does not animate (useful for
 * testing)
 */
export const StaticStyleTransition = React.forwardRef(
  (props: StyleTransitionProps, ref) => <AnimatedBox {...props} ref={ref} />,
)

/**
 * `<StyleTransition>` allows you to transition animatable style properties
 * between two states. It also allows animating the entrance/exit of a
 * component.
 *
 * Use the `progress` value to set the current state (from 0 - 1) of the animated props.
 * By default, the component will auto transition into the first state (ie. from
 * progress 0 -> 1). However, you can disable entrance animations by passing
 * `animateEntrance={false}`.
 */
export const StyleTransition = React.forwardRef(
  ({ disableAnimations, ...rest }: StyleTransitionProps, ref) => {
    return disableAnimations ? (
      <StaticStyleTransition {...rest} ref={ref} />
    ) : (
      <AnimatedStyleTransition {...rest} ref={ref} />
    )
  },
)
