import React from 'react'
import { Text as RNText, type TextProps as RNTextProps } from 'react-native'
import {
  createRestyleComponent,
  textShadow,
  type TextShadowProps,
  typography,
  type TypographyProps,
} from '@shopify/restyle'
import {
  type TextVariantProps,
  textVariants,
  type ForegroundColorProps,
  foregroundColors,
  textShadowColors,
  textDecorationColors,
  TextDecorationColorProps,
  ResponsiveValue,
} from '~/theme'
import { BoxRestyleProps, boxRestyleFunctions } from '../box'
import { getFont, useResponsiveProp } from '~/theme'
import { AptTheme, useTheme } from '~/theme'

export type TextRestyleProps = ForegroundColorProps &
  TextShadowProps<AptTheme> &
  TypographyProps<AptTheme> &
  TextDecorationColorProps &
  TextVariantProps

export type TextBaseProps = RNTextProps & BoxRestyleProps & TextRestyleProps

export const textRestyleFunctions = [
  foregroundColors,
  textVariants,
  textShadowColors,
  textDecorationColors,
  // These need to be defined last so our customized props will override those
  // that come from Restyle.
  typography,
  textShadow,
]

export const TextBase = createRestyleComponent<TextBaseProps, AptTheme>(
  [...textRestyleFunctions, ...boxRestyleFunctions],
  RNText,
)

type FontFamilyValues = keyof AptTheme['fontFamilies']
type FontWeightValues = keyof AptTheme['fontWeights']
type TextVariantValues = keyof AptTheme['textVariants']

export type TextProps = Omit<TextBaseProps, 'fontFamily'> & {
  fontFamily?: ResponsiveValue<FontFamilyValues>
}

export type TextRef = typeof TextBase

/**
 * Get the font for a given text variant, optionally overriding the family and
 * weight.
 */
export const useTextVariantFont = (
  variantProp: ResponsiveValue<TextVariantValues>,
  fontFamilyProp?: ResponsiveValue<FontFamilyValues>,
  fontWeightProp?: ResponsiveValue<FontWeightValues>,
) => {
  const theme = useTheme()

  const variant = useResponsiveProp<keyof AptTheme['textVariants']>(variantProp)
  if (variantProp) {
    const v = theme.textVariants[variant]
    // Use the variant definitions if the `fontFamily` or `fontWeight` where not
    // specifically set.
    if (!fontFamilyProp)
      fontFamilyProp = v.fontFamily as ResponsiveValue<FontFamilyValues>
    if (!fontWeightProp)
      fontWeightProp = v.fontWeight as ResponsiveValue<FontWeightValues>
  }

  const fontWeightKey =
    useResponsiveProp<keyof AptTheme['fontWeights']>(fontWeightProp)
  const fontFamilyKey =
    useResponsiveProp<keyof AptTheme['fontFamilies']>(fontFamilyProp)

  return getFont(theme, fontFamilyKey, fontWeightKey)
}

/**
 * `Text` is a primitive text component that is the base of our other
 * components. You should generally use one of the specializations of this
 * component such as `Title` or `Label` unless you specifically need dynamic
 * text sizing or to change text styling responsively. On web it will render a
 * `<div>` and on native a `<Text>`.
 *
 * `Text` is derived from `@shopify/restyle`, giving you styling props connected
 * to our theme. You can override styles or use styles that aren't defined in
 * our theme by using the standard `style` prop.
 *
 * ```jsx
 * <Text
 *   // This will use the color called `primary.200` in our theme.
 *   // See `~/theme/themes/light/palettes`
 *   color="primary.200"
 *   // This will use the margin size "s" from our theme.
 *   margin="s"
 *   // This will set the padding responsively to the screen dimensions
 *   p={{phone: 'm', tablet: 'l'}}
 *   // Anything in the style prop will override the attribute styles.
 *   // You can also specify values that don't exist in our theme.
 *   style={{
 *     backgroundColor: '#ff00ff',
 *   }}
 * />
 * ```
 *
 * For more details, check out the [Restyle
 * docs](https://shopify.github.io/restyle/fundamentals/restyle-functions)
 */
export const Text = React.forwardRef<TextRef, TextProps>(
  (
    {
      /**
       * Takes precedence over `variant` if the text variant includes a `fontFamily`
       * prop.
       */
      fontFamily,
      /**
       * Takes precedence over `variant` if the text variant includes a `fontWeight`
       * prop.
       */
      fontWeight,
      variant,
      color = 'normal',
      style,
      ...props
    },
    ref,
  ) => {
    const font = useTextVariantFont(variant, fontFamily, fontWeight)
    return (
      <TextBase
        testID="Text"
        color={color}
        style={[{ fontFamily: font }, style]}
        // Also pass the variant so we apply any other font features like italics.
        variant={variant}
        {...props}
        ref={ref}
      />
    )
  },
)
